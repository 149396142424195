import axios from 'axios'
import * as urls from './urls'

/**
 * EFFECTS are used to expose existing libraries or create APIs
 * the functions can then be called by actions
 */

export const webPassAPI = {
  async listClients (data) {
    let url = (urls.API_CLIENTS)
    return axios.get(url, { params: data })
  },
  async listOrdersByInstallation (data) {
    let url = (urls.API_ORDERS_BY_INSTALLATION).replace("{installation_id}", data.id)
    delete data.id
    return axios.get(url, { params: data })
  },
  async createOrUpdateClient (data) {
    if (data.id == null) delete data.id
    let url = (urls.API_CLIENTS)
    return axios.put(url, data)
  },
  async listSubscriptionPlans (data) {
    let url = (urls.API_SUBSCRIPTION_PLANS)
    return axios.get(url, { params: data })
  },
  async createOrUpdateSubscriptionPlan (data) {
    if (data.id == null) delete data.id
    let url = (urls.API_SUBSCRIPTION_PLANS)
    return axios.put(url, data)
  },
  async listPrices (data) {
    let url = (urls.API_PRICES)
    return axios.get(url, { params: data })
  },
  async listRules (data) {
    let url = (urls.API_RULES)
    return axios.get(url, { params: data })
  },
  async createOrUpdatePrice (data) {
    if (data.id == null) delete data.id
    let url = (urls.API_PRICES)
    return axios.put(url, data)
  },
  async listInstallationTargets (data) {
    let url = (urls.API_INSTALLATIONS_TARGETS).replace("{installation_id}", data.id)
    return axios.get(url)
  },
  async getInstallationById (data) {
    let url = (urls.API_INSTALLATIONS_BY_ID).replace("{installation_id}", data.id)
    return axios.get(url)
  },
  async createInstallation (data) {
    if (data.id) delete data.id
    let url = (urls.API_INSTALLATIONS)
    return axios.put(url, data)
  },
  async UpdateInstallation ({ id, client, name, address }) {
    if (!id) return
    let url = (urls.API_INSTALLATIONS_BY_ID).replace("{installation_id}", id)
    return axios.patch(url, { id, client: client.id, name, address })
  },
  async createOrUpdateTarget (data) {
    if (data.id == null) delete data.id
    try { delete data.options } catch { /*option already not present*/ }
    let url = (urls.API_LICENSE_TARGETS)
    return axios.put(url, data)
  },
  async deleteTarget ({ id }) {
    if (!id) return
    let url = (urls.API_LICENSE_TARGETS_BY_ID.replace("{license_target_id}", id))
    return axios.delete(url)
  },
  async createOrUpdateOptionList (data) {
    let url = (urls.API_OPTIONS)
    return axios.post(url, data)
  },
  async createOrUpdateLicence (data) {
    let url = (urls.API_ACTIVATE_LICENSE)
    return axios.put(url, data)
  },
  async cancelLicense (data) {
    let url = (urls.API_ACTIVATE_LICENSE)
    return axios.patch(url, data)
  },
  async forceRegenLicense (data) {
    let url = (urls.API_INSTALLATIONS_BY_ID.replace("{installation_id}", data))
    return axios.post(url)
  },
  async getMe () {
    let url = (urls.API_ME)
    return axios.get(url)
  },
  async getExchangeRate () {
    let url = (urls.API_EXCHANGE_RATE)
    return axios.get(url)
  },
  async forceJobs () {
    let url = (urls.API_FORCE_JOBS)
    return axios.post(url)
  },
  async forceRenewDate (data) {
    let url = (urls.API_FORCE_RENEW_DATE).replace("{installation_id}", data)
    return axios.patch(url)
  },
}
