<template lang="pug">
.bloc.my-3
  .tile.is-ancestor
    .tile.is-4.is-vertical
      .content
        h3 {{ client.name }}
        p {{ client.desc }}
        h4.has-text-info(v-if="state.user.is_staff") Reseller : {{ client.user.username }}
    .tile.is-8.is-vertical.is-parent
      .tile.is-child
        .content.bordered(
          v-for="(installation, index) in client.installations"
        )
          .columns
            .column.is-6.content
              h4 {{ installation.name }}
                b-icon.mx-2(
                  v-if="installation.license && installation.license.status != 'canceled'",
                  type="is-success",
                  icon="badge-check"
                ) 
                span.mx-2(
                  v-if="installation.license && installation.license.status == 'canceled'"
                )
                  b-icon(type="is-warning", icon="ban") 
              span {{ installation.address }}
            .column.is-3
              .content(v-if="installation.license")
                p(v-if="installation.license.last_renew_date") Last : {{ installation.license.last_renew_date ? installation.license.last_renew_date : '----' }}
                  XhrefPrice(
                    :EURprice="installation.license.last_price",
                    :convert="false"
                  ) )
                p {{ validUntilDate(installation.license.next_renew_date) }}
                p.has-text-danger(
                  v-if="installation.license && installation.license.status == 'canceled'"
                ) Canceled
            .column.is-3
              b-button.is-outlined.is-rounded.is-info(
                type="is-primary",
                icon-left="edit",
                icon-pack="fas",
                @click="goToDetailsPage(installation, client)"
              ) Edit
      .tile.is-child
        b-button.is-outlined.is-rounded(
          type="is-primary",
          icon-left="user-plus",
          icon-pack="fas",
          @click="showForm()"
        ) Add installation
  b-modal(
    v-model="isComponentModalActive",
    :destroy-on-hide="true",
    aria-operation="dialog",
    aria-modal,
    :props="modalProps",
    :parent="this",
    :component="modalComponent",
    :refreshCallback="refreshCallback"
  )
</template>

<script>
import InstallationForm from './InstallationForm'
import { snackError } from '../utils/common'
import XhrefPrice from '../partial/XhrefPrice.vue'
import { DateTime } from 'luxon'

export default {
  props: {
    client: Object,
    refreshCallback: Function
  },
  components: {
    XhrefPrice
  },
  data: function () {
    return {
      refresh: 0,
      sendTo: this.actions.getClients,
      sendData: {},
      snackError: snackError,
      isComponentModalActive: false,
      modalComponent: InstallationForm,
      modalProps: {
        installation: {
          id: null,
          name: null,
          client: null,
        },
        refreshCallback: this.refreshCallback
      },
    }
  },
  methods: {
    validUntilDate: function (isoDate) {
      let date = DateTime.fromISO(isoDate).minus({ days: 10 })
      if (date < DateTime.now()) {
        return "Waiting for activation"
      }
      return "Valid until :" + isoDate.toLocaleString()
    },
    goToDetailsPage: function (installation, client) {
      let client_data = { ...client }
      delete (client_data.installations)
      installation.client = client_data
      this.actions.addInstallationToCache(installation)
      this.$router.push({ name: 'installation_licenses', params: { installation_id: installation.id } })
    },
    doRefresh () { this.$$emit('new-installation') },
    showForm (props) {
      if (props) {
        this.modalProps.installation = props
      }
      else {
        this.modalProps.installation = {
          id: null,
          name: null,
          client: this.client.id
        }
      }
      this.isComponentModalActive = true
    },
  }
}
</script>

<style>
.content.bordered {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}
</style>