<template lang="pug">
b-skeleton(v-if="isLoading")
div(v-else-if="!isLoading")
  .px-6
    Hero()
      template(v-slot:left)
        div
          h2.is-size-4.mb-0.left-title {{ installation.client.name }}
          .is-flex.is-align-items-center
            b-button.is-outlined.is-small.mr-3(
                      type="is-primary",
                      icon-left="edit",
                      icon-pack="fas",
                      @click="showUpdateInstallationForm"
                    )
            h1.title.mb-0.left-title {{ installation.name }}
          p.pl-2 {{ installation.address }}
      LicenseDateResume.content(
        :installation="installation",
        v-if="installation.license",
        :buttonLeft="true"
      )
        template(v-slot:btn="props")
          b-button.is-outlined(@click="goToOrdersHistoryPage(installation)") View history

  .columns.px-6
    .column.is-5
      b-collapse.card.my-2(:aria-id="EQUIPMENT", animation="slide")
        .card-header(
          slot="trigger",
          slot-scope="props",
          role="button",
          :aria-controls="EQUIPMENT"
        )
          p.card-header-title Machines
          a.card-header-icon
            b-icon(v-if="props.open", icon="chevron-double-up")
            span(v-if="!props.open")
              b-icon(icon="chevron-double-down")

        .card-content
          .bordered.content(
            v-for="(target, index) in targets",
            v-if="target.target_type == EQUIPMENT"
          )
            Target(
              :target="target",
              :index="index",
              :type="EQUIPMENT",
              :isValidated="isValidated",
              :refresher="refresher",
              :key="index + 'E' + refresher",
              @on-change="onChange",
              @on-undo="resetOneTarget"
            )
              template(v-slot:edit="props")
                b-tooltip(
                    label="Please save before update",
                    :active="target.hasChange",
                    type="is-danger"
                  )
                  b-button.is-outlined.is-small.mr-3(
                    type="is-primary",
                    :disabled="target.hasChange"
                    icon-left="edit",
                    icon-pack="fas",
                    @click="showAddTargetForm(EQUIPMENT, target)"
                  )
          .content
            b-button.is-fullwidth.is-outlined.is-rounded(
              type="is-primary",
              icon-left="plus",
              icon-pack="fas",
              @click="showAddTargetForm(EQUIPMENT)"
            ) Add Machine

      b-collapse.card.my-2(:aria-id="SOFTWARE", animation="slide")
        .card-header(
          slot="trigger",
          slot-scope="props",
          role="button",
          :aria-controls="SOFTWARE"
        )
          p.card-header-title Users
          a.card-header-icon
            b-icon(v-if="props.open", icon="chevron-double-up")
            span(v-if="!props.open")
              b-icon(icon="chevron-double-down")
        .card-content
          .bordered.content(
            v-for="(target, index) in targets",
            v-if="target.target_type == SOFTWARE"
          )
            Target(
              :target="target",
              :index="index",
              :type="SOFTWARE",
              :isValidated="isValidated",
              :refresher="refresher",
              :key="index + 'S' + refresher",
              @on-change="onChange",
              @on-undo="resetOneTarget"
            )
              template(v-slot:edit="props")
                b-tooltip(
                  label="Please save or reset modification before edit",
                  :active="target.hasChange",
                  type="is-primary"
                  position="is-right"
                )
                  b-button.is-outlined.is-small.mr-3(
                    type="is-primary",
                    :disabled="target.hasChange"
                    icon-left="edit",
                    icon-pack="fas",
                    @click="showAddTargetForm(SOFTWARE, target)"
                  )
          .content
            b-button.is-fullwidth.is-outlined.is-rounded(
              type="is-primary",
              icon-left="plus",
              icon-pack="fas",
              @click="showAddTargetForm(SOFTWARE)"
            ) Add User
    .column.is-7
      div(style="position: sticky; top: 20px; min-height: 50vh")
        .bordered.content.card.my-2
          table.is-narrow
            thead
              tr
                th Subscription plan
                th Quantity
                th Unit price
                th Users per license
                th Sell price
                th Purchase price
            tbody
              tr(
                v-for="(planPrice, index) in resume",
                v-if="planPrice.quantity > 0",
                :class="planPrice.isChanged ? 'has-text-info' : ''"
              )
                td {{ planPrice.name }}
                td {{ planPrice.quantity }}
                td
                  XhrefPrice(:EURprice="planPrice.unit_price")
                td {{ planPrice.per_user }}
                td
                  XhrefPrice(:EURprice="planPrice.total_price")
                td.is-flex.is-align-items-center
                  XhrefPrice(
                    :EURprice="planPrice.total_price * planPrice.vendor_percentage"
                  )
                  //- span.pl-1.is-small.content ( {{ planPrice.vendor_percentage * 100 }}% )

            tfoot
              tr
                th(v-if="state.user.currency != 'EUR'", colspan="3")
                  p.my-0 Exchange reference rates : 1 EUR = {{ state.exchangeRate }} {{ state.currency }}
                  p.content.is-small
                    span(v-for="(val, key) in discountApplied") {{ val + " , " }}
                  //- p.content.is-small
                  //-   //- a(
                  //-   //-   href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html",
                  //-   //-   target="_blank"
                  //-   //- ) Source ECB
                  //-   span Source ALMA
                  //-   span {{ ' (' + state.rate_exchange_date }} )
                th(v-else, colspan="3")
                th.has-text-right Total :
                th
                  XhrefPrice(:EURprice="resume.total_selling")
                th
                  XhrefPrice(:EURprice="resume.total_vendor")

          .is-flex.is-justify-content-space-around.is-align-items-center
            b-button.is-outlined.px-6.ml-5(
              type="is-primary",
              icon-left="undo",
              icon-pack="fas",
              @click="resetAll",
              :disabled="!hasChange"
            ) Reset all
            b-button.is-outlined.px-6.ml-5(
              type="is-primary",
              icon-left="save",
              icon-pack="fas",
              @click="saveChange",
              :disabled="!hasChange || hasError"
            ) Save
        .bordered.content.card.my-2(style="overflow: visible")
          .is-flex.is-justify-content-space-around.is-align-items-center.is-fullwidth(
            v-if="!installation.license || installation.license.status == 'canceled'"
          )
            b-field(label="Installation date", grouped)
              b-datepicker(
                ref="datepicker",
                expanded,
                placeholder="select a date...",
                icon="calendar-alt",
                editable=false,
                v-model="activationDate",
                :disabled="hasError || hasChange || resume.total_vendor <= 0",
                :minDate="minDate",
                :append-to-body="true"
              )
              p.control
                b-button.is-outlined.px-6(
                  type="is-success",
                  icon-left="shield-check",
                  icon-pack="fas",
                  @click="activateLicense",
                  :disabled="hasError || hasChange || resume.total_vendor <= 0"
                ) Activate License
          LicenseDateResume(
            :installation="installation",
            :buttonLeft="false",
            v-if="installation.license && installation.license.status != 'canceled'"
          )
            template(v-slot:btn="props")
              .block.mx-2.my-1(
                v-if="state.user.is_superuser && installation.license.status != 'canceled'"
              )
                b-button.is-warning(@click="forceDateAtToday(installation)") Change Renew Date at now
              .block.mx-2.my-1(v-if="installation.license.status != 'init'")
                b-tooltip(
                  v-if="installation.license.status != 'canceled'",
                  :label="forceRegenTooltipsText",
                  :active="forceRegenTooltipsText != ''",
                  type="is-danger"
                )
                  b-button.is-danger.is-light.is-outlined(
                    @click="showRegenlConfirm(installation)",
                    :loading="forceRegenRunning",
                    :disabled="installation.license.status != 'renewed' || installation.license.modified == false"
                  ) Force license(s) re-delivery
              .block.mx-2.my-1
                b-button.is-danger(@click="showCancelConfirm(installation)") Cancel License

  b-modal(
    v-model="isComponentModalActive",
    :destroy-on-hide="true",
    aria-operation="dialog",
    aria-modal,
    :props="modalProps",
    :parent="this",
    :component="modalComponent"
  )
</template>

<script>
  import Target from "./TargetPage";
  import Hero from "../partial/HerosWithButton";
  import { cloneDeep } from "lodash";
  import { snackError } from "../utils/common";
  import TargetForm from "./TargetForm";
  import SaveReview from "./SaveReview";
  import { DateTime } from "luxon";
  import LicenseDateResume from "./LicenseDateResume.vue";
  import CancelLicence from "./CancelLicence.vue";
  import RegenLicense from "./RegenLicense.vue";
  import XhrefPrice from "../partial/XhrefPrice.vue";
  import InstallationForm from '../clients/InstallationForm'

  const REQUIRE = "require";
  const REQUIRE_LICENSE = "require_license";
  const INCOMPATIBILITY = "incompatibility";
  const EQUIPMENT = "equipment";
  const SOFTWARE = "software";

  export default {
    components: {
      Hero,
      Target,
      LicenseDateResume,
      XhrefPrice,
    },
    data: function () {
      return {
        EQUIPMENT: EQUIPMENT,
        SOFTWARE: SOFTWARE,
        activationDate: null,
        isLoading: true,
        isValidated: false,
        targets: [],
        initTargets: [],
        installation: {},
        childTargets: {},
        indexedPlan: null,
        refresher: 0,
        resume: [],
        initResume: null,
        sendTo: this.actions.getClients,
        sendData: {},
        snackError: snackError,
        isComponentModalActive: false,
        modalComponent: null,
        modalProps: null,
        hasChange: false,
        hasError: false,
        discountApplied: [],
        forceRenewDateRunning: false,
        forceRegenRunning: false,
      };
    },
    computed: {
      minDate: function () {
        let minDate = DateTime.local();
        if (this.installation)
          if (this.installation.license)
            if (
              DateTime.fromISO(this.installation.license.next_renew_date) >
              minDate
            )
              minDate = DateTime.fromISO(
                this.installation.license.next_renew_date
              );
        // console.log(minDate.toLocaleString());
        return minDate.toJSDate();
      },
      installationId: function () {
        return this.$route.params.installation_id;
      },
      forceRegenTooltipsText: function () {
        let text = "";
        if (this.installation.license.status == "order_generated")
          text = "please wait for the license(s) delivery";
        if (this.installation.license.modified == false)
          text = "no license's modification has been saved";
        return text;
      },
    },
    created: async function () {
      await this.refresh();
      this.activationDate = this.minDate;
      // console.log(this.activationDate);
    },
    methods: {
      goToOrdersHistoryPage: function (installation) {
        this.actions.addInstallationToCache(installation);
        this.$router.push({
          name: "installation_orders",
          params: { installation_id: installation.id },
        });
      },
      activateLicense: async function () {
        try {
          await this.actions.createOrUpdateLicence({
            id: this.installation.id,
            activation_date: DateTime.fromJSDate(this.activationDate).toISO(),
          });
        } catch (error) {
          snackError(error);
        } finally {
          this.reloadPage();
        }
      },
      reloadPage() {
        window.location.reload();
      },
      refresh: async function () {
        try {
          this.isLoading = true;
          this.installation = await this.actions.getInstallationById({
            id: this.installationId,
          });
          this.targets = await this.actions.listInstallationTargets({
            id: this.installationId,
          });
          this.initTargets = cloneDeep(this.targets);
          this.resume = [];
          this.initResume = null;
          this.compileTarget();
          this.initResume = cloneDeep(this.resume);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          snackError(error);
        }
      },
      resetAll: function () {
        this.targets = cloneDeep(this.initTargets);
        this.compileTarget();
      },
      showAddTargetForm(target_type, target) {
        (this.modalComponent = TargetForm),
          (this.modalProps = {
            target: {
              id: target ? target.id : null,
              name: target ? target.name : null,
              desc: target ? target.desc : null,
              dongle_id: target ? target.dongle_id : null,
              target_type: target_type,
              installation: this.installation.id,
              options: target ? target.options : null
            },
            refreshCallback: this.addTarget,
          });
        this.isComponentModalActive = true;
      },
      showUpdateInstallationForm() {
        (this.modalComponent = InstallationForm),
          (this.modalProps = {
            installation: this.installation,
            refreshCallback: this.refresh,
          });
        this.isComponentModalActive = true;
      },
      showSaveReview(optionReview) {
        (this.modalComponent = SaveReview),
          (this.modalProps = {
            optionReview: optionReview,
            installationID: this.installation.id,
            refreshCallback: this.refresh,
          });
        this.isComponentModalActive = true;
      },
      showCancelConfirm(installation) {
        (this.modalComponent = CancelLicence),
          (this.modalProps = {
            installation: installation,
            refreshCallback: this.reloadPage,
          });
        this.isComponentModalActive = true;
      },
      forceDateAtToday: async function (installation) {
        try {
          this.forceRenewDateRunning = true;
          await this.actions.forceRenewDate(installation.id);
          await new Promise((r) => setTimeout(r, 3000)); // wait few second before allowing a second forceRenewDate to run
          this.forceRenewDateRunning = false;
          window.location.reload();
        } catch (error) {
          snackError(error);
          this.forceRenewDateRunning = false;
        }
      },
      showRegenlConfirm(installation) {
        this.modalComponent = RegenLicense;
        this.modalProps = {
          installation: installation,
          refreshCallback: this.reloadPage,
        };
        this.isComponentModalActive = true;
      },
      addTarget(target, isNew, deleted) {
        if(deleted){
          this.refresh()
        } else if (isNew) {
          this.targets.push(target);
          this.initTargets.push(cloneDeep(target));
        } else {
          for (var i = 0; i < this.targets.length; i++) {
            if (this.targets[i].id == target.id) {
              this.targets[i] = target;
              this.initTargets[i] = target;
              break;
            }
          }
        }
        this.compileTarget();
      },
      onChange: function (targetIndex, optionIndex) {
        // option already exist
        if (this.initTargets[targetIndex].options[optionIndex]){
          if ( // option is the same plan has existing
            this.targets[targetIndex].options[optionIndex].plan ==
            this.initTargets[targetIndex].options[optionIndex].plan
          ) {
            // option is the same plan has previous (reset)
            if (this.targets[targetIndex].options[optionIndex].previous){
              delete this.targets[targetIndex].options[optionIndex].previous;
            }
            this.targets[targetIndex].hasChange = false;
          } else { // option has been modified
            this.targets[targetIndex].options[optionIndex].previous = cloneDeep(
              this.initTargets[targetIndex].options[optionIndex]
            );
            this.targets[targetIndex].hasChange = true;
          }
        }else{ // it's a new option
          // a new option has been added
          if(this.targets[targetIndex].options[optionIndex].plan){
            this.targets[targetIndex].hasChange = true;
          }else{ // a new option has been removed
            this.targets[targetIndex].options.splice(optionIndex, 1)
            // check if other new options
            if(this.initTargets[targetIndex].options.length == this.targets[targetIndex].options.length-1){
              this.targets[targetIndex].hasChange = false;
            }
          }
        }
        this.compileTarget();
      },
      resetOneTarget: function (targetIndex) {
        this.targets[targetIndex] = cloneDeep(this.initTargets[targetIndex]);
        this.compileTarget();
      },
      emptyOption() {
        let empty = {
          plan: "",
          hasError: false,
          errMessage: "",
          price: 0,
          vendor_percentage: 0,
          userCount: 0,
          userPerPlan: 1,
          plansList: [],
        };
        return { ...empty };
      },
      compileTarget: function () {
        // console.log("compiling...")
        this.isValidated = false;
        this.hasError = false;
        // dict planId => plan
        let indexedPlan = this.actions.getCacheIndexedSubscriptionPlans();

        // init properties
        Object.keys(indexedPlan).forEach((planId) => {
          indexedPlan[planId].count = 0;
          indexedPlan[planId].total_price = 0;
          indexedPlan[planId].isValid = true;
          indexedPlan[planId].usedBySelector = [];
        });

        /**  for each selected plan :
         *       - count how many time a plan is selected
         *       - index option using plan.
         */
        for (let target of this.targets) {
          let addEmpty = true;
          target.options = target.options.filter(function (option) {
            return option.plan != "" || option.previous;
          });

          for (let option of target.options) {
            let planId = option.plan;

            if (planId != "") {
              indexedPlan[planId].count = indexedPlan[planId].count + 1;
              indexedPlan[planId].usedBySelector.push(option);
            } else {
              addEmpty = false;
            }
          }
          // Add empty option (will add a empty select)
          if (
            (target.target_type == SOFTWARE && addEmpty) ||
            target.options.length == 0
          )
            target.options.push(this.emptyOption());
        }

        // get rules, indexed rules based on target type
        let equipmentRules = this.actions.getCacheIndexedEquipmentRulesPlan();
        let softwareRules = this.actions.getCacheIndexedSoftwareRulesPlan();

        // check rule on equipment to disable plan that require a not selected machine pack, or excluded by a selected machine pack
        // set plan.isValid true/false to plan, based on equipment rules.
        indexedPlan = this.checkEquipmentRules(indexedPlan, equipmentRules);

        // for each option : int/reset info and set witch plan can be selected or not (isValid true/false)
        for (let target of this.targets) {
          for (let option of target.options) {
            option.hasError = false;
            option.errMessage = "";
            option.price = 0;
            option.vendor_percentage = 0;
            option.userCount = 0;
            option.userPerPlan = 1;
            // rule check : set isValid true/false
            option.plansList = this.checkSoftwareRules(
              indexedPlan,
              softwareRules,
              target,
              option
            );
            // Display errors if any
            if (option.plan != "")
              for (let plan of option.plansList) {
                if (plan.id == option.plan) {
                  option.hasError = !plan.isValid;
                  option.errMessage = plan.isValid
                    ? ""
                    : this.buildErrorMessage(plan.id);
                  this.hasError = this.hasError ? this.hasError : option.hasError;
                  break;
                }
              }
          }
        }
        this.resume = {}; // bill/invoice compilation
        this.discountApplied = [];
        this.resume.add = function (plan, planId, price, countInit) {
          this[planId + "-" + price.nb_users] = {
            name:
              plan.name +
              (price.nb_users > 1 ? "( " + price.nb_users + " )" : ""),
            quantity: Math.ceil(plan.count / price.nb_users),
            unit_price: price.value,
            total_price: Math.ceil(plan.count / price.nb_users) * price.value,
            per_user:
              price.nb_users > 1
                ? countInit +
                  "/" +
                  Math.ceil(plan.count / price.nb_users) * price.nb_users
                : "",
            vendor_percentage: plan.price[0].vendor_percentage,
          };
        };
        // used indexed plan quantity to set price value. => for option with multiple user per option.
        for (let planId in indexedPlan) {
          let plan = indexedPlan[planId];
          let countUse = 0;

          if (plan.discounts.length > 0) {
            for (let discount of plan.discounts) {
              if (discount.condition == "only_license") {
                let applyDiscount = false;
                for (let requiredPlanId of discount.plans) {
                  if (indexedPlan[requiredPlanId].count > 0) {
                    applyDiscount = true;
                    break;
                  }
                }

                for (let planIdToCheck in indexedPlan) {
                  if (
                    applyDiscount &&
                    planId != planIdToCheck &&
                    indexedPlan[planIdToCheck].target_type == SOFTWARE &&
                    !discount.plans.includes(parseInt(planIdToCheck)) &&
                    indexedPlan[planIdToCheck].count > 0
                  )
                    applyDiscount = false;
                }
                if (applyDiscount) {
                  for (let price of plan.price) {
                    price.value = price.value * discount.value;
                    this.discountApplied.push(
                      "" + plan.name + " : " + discount.value * 100 + "%"
                    );
                  }
                }
              }
            }
          }

          if (plan.count > 0) {
            let countInit = plan.count;

            for (let selector of plan.usedBySelector) {
              for (let price of plan.price) {
                selector.price = price.value;
                selector.userPerPlan = price.nb_users;

                if (price.nb_users == 1) {
                  // option has price for 1 user/machine
                  this.resume.add(plan, planId, price, countInit);
                  selector.vendor_percentage = price.vendor_percentage;
                  selector.userCount = price.nb_users;
                } else {
                  if (countInit <= price.nb_users) {
                    // price is for multiple user
                    this.resume.add(plan, planId, price, countInit);
                    selector.vendor_percentage = price.vendor_percentage;
                    selector.userCount = countInit;

                    countUse = countUse + 1;
                    if (countUse == price.nb_users) {
                      countInit = countInit - countUse;
                      countUse = 0;
                    }

                    break;
                  } else {
                    // user count is superior to max user for a price

                    if (plan.price.indexOf(price) == plan.price.length - 1) {
                      this.resume.add(plan, planId, price, countInit);
                      selector.vendor_percentage = price.vendor_percentage;
                      selector.userCount = price.nb_users;

                      countUse = countUse + 1;
                      if (countUse == price.nb_users) {
                        countInit = countInit - countUse;
                        countUse = 0;
                      }

                      break;
                    }
                  }
                }
              }
            }
          }
        }

        this.computeTotalResume();
        this.indexedPlan = indexedPlan;
        this.refresher = this.refresher + 1; // for vue to update component
        this.isValidated = true;
        // console.log("...compiled")
      },
      checkEquipmentRules: function (indexedPlan, equipmentRules) {
        // for each plan
        for (let planId in indexedPlan) {
          let plan = indexedPlan[planId];
          let rules = equipmentRules[planId]; // get equipment rules for the plan (if any)
          let isValid = true;
          if (rules) {
            let oneRequirementSatisfied = false;
            for (let rule of rules) {
              // plan has requirement :
              if (rule.rule == REQUIRE && !oneRequirementSatisfied) {
                isValid = false; // set valid false until one requirement is available
                if (indexedPlan[rule.target_plan].count >= 1) {
                  isValid = true;
                  oneRequirementSatisfied = true;
                  continue;
                }
              }
              // plan has exclude
              if (rule.rule == INCOMPATIBILITY && isValid) {
                if (indexedPlan[rule.target_plan].count >= 1) {
                  isValid = false; // set valid false if an excluder plan is used
                  break;
                }
              }
            }
          }
          plan.isValid = isValid; // set is valid
        }

        return indexedPlan;
      },
      checkSoftwareRules: function (indexedPlan, softwareRules, target, option) {
        let plansList = this.actions.getCacheSubscriptionPlans();
        let currentSelectedPlanId = option.plan;
        let usedPlans = {};

        for (let li of target.options) {
          if (li.plan != "") usedPlans[li.plan] = true;
        }

        // for each available plan
        for (let plan of plansList) {
          let isValid = indexedPlan[plan.id].isValid; // set isValid from computed value by checkEquipmentRules

          if (!isValid) {
            // next if already invalid
            plan.isValid = isValid;
            continue;
          }

          if (usedPlans[plan.id] && currentSelectedPlanId != plan.id) {
            // disable if already selected
            plan.isValid = false;
            continue;
          }

          let rules = softwareRules[plan.id];
          if (rules) {
            let oneRequirementSatisfied = false;
            let oneLicenseRequirementSatisfied = false;
            for (let rule of rules) {
              // plan has requirement
              if (rule.rule == REQUIRE && !oneRequirementSatisfied) {
                if (currentSelectedPlanId != rule.target_plan) {
                  // ignore currently selected to be used as a requirement
                  isValid = false; //set valid false until one requirement is available
                  if (usedPlans[rule.target_plan]) {
                    isValid = true;
                    oneRequirementSatisfied = true;
                    continue;
                  }
                }
              }
              // plan has license requirement
              if (
                rule.rule == REQUIRE_LICENSE &&
                !oneLicenseRequirementSatisfied
              ) {
                isValid = false; // set valid false until one requirement is available
                if (
                  (currentSelectedPlanId != rule.target_plan &&
                    indexedPlan[rule.target_plan].count >= 1) ||
                  indexedPlan[rule.target_plan].count >= 2
                ) {
                  // ignore currently selected to be used as a requirement

                  isValid = true;
                  oneLicenseRequirementSatisfied = true;
                  continue;
                }
              }
              // plan has incompatibility
              if (rule.rule == INCOMPATIBILITY && isValid) {
                if (currentSelectedPlanId != rule.target_plan)
                  if (usedPlans[rule.target_plan]) {
                    // ignore currently selected to be used as a excluder
                    isValid = false; // set valid false if an excluder plan is used
                    break;
                  }
              }
            }
          }
          plan.isValid = isValid;
        }
        return plansList;
      },
      buildErrorMessage: function (planId) {
        let equipmentRules = this.actions.getCacheIndexedEquipmentRulesPlan();
        let softwareRules = this.actions.getCacheIndexedSoftwareRulesPlan();
        let indexedPlan = this.actions.getCacheIndexedSubscriptionPlans();
        //let planName = indexedPlan[planId].name
        let message = [];
        message.push(indexedPlan[planId].name + " :");
        if (equipmentRules[planId])
          for (let rule of equipmentRules[planId]) {
            if (rule.rule == REQUIRE) {
              message.push(" - require " + indexedPlan[rule.target_plan].name);
            }

            if (rule.rule == INCOMPATIBILITY) {
              message.push(
                " -  is incompatible with " + indexedPlan[rule.target_plan].name
              );
            }
          }
        if (softwareRules[planId])
          for (let rule of softwareRules[planId]) {
            if (rule.rule == REQUIRE) {
              message.push(" -  require " + indexedPlan[rule.target_plan].name);
            }
            if (rule.rule == REQUIRE_LICENSE) {
              message.push(
                " - order must contains a " + indexedPlan[rule.target_plan].name
              );
            }
            if (rule.rule == INCOMPATIBILITY) {
              message.push(
                " -  is incompatible with " + indexedPlan[rule.target_plan].name
              );
            }
          }
        return message;
      },
      computeTotalResume() {
        this.hasChange = false;
        let total_selling = 0;
        let total_vendor = 0;
        for (let planId in this.resume) {
          if (planId != "add") {
            let planPrice = this.resume[planId];
            total_selling = total_selling + planPrice.total_price;
            total_vendor =
              total_vendor + planPrice.total_price * planPrice.vendor_percentage;
            if (this.initResume) {
              if (
                Object.keys(this.initResume).length !=
                Object.keys(this.resume).length
              )
                this.hasChange = true;
              if (this.initResume[planId]) {
                let initPlanPrice = this.initResume[planId];
                if (
                  planPrice.quantity != initPlanPrice.quantity ||
                  planPrice.per_user != initPlanPrice.per_user
                ) {
                  planPrice.isChanged = true;
                  this.hasChange = true;
                }
              } else {
                planPrice.isChanged = true;
                this.hasChange = true;
              }
            }
          }
          if(!this.hasChange){
            this.targets.forEach(target=>{
              if(target.hasChange){
                this.hasChange = true
              }
            })
          }
          this.resume.total_selling = total_selling;
          this.resume.total_vendor = total_vendor;
        }
      },
      saveChange() {
        if (this.targets.length != this.initTargets.length)
          snackError({}, "Something wrong");
        let optionReview = [];
        let nbTargets = this.targets.length;
        for (let i = 0; i < nbTargets; i++) {
          let target = this.targets[i];
          let initTargets = this.initTargets[i];
          for (let option of target.options) {
            option.targetName = target.name;
            option.license_target = target.id;
            if (!option.id && option.plan != "") {
              option.status = "new";
              optionReview.push(option);
            }
            if (option.id)
              for (let initOption of initTargets.options) {
                if (option.id == initOption.id) {
                  if (option.plan == initOption.plan) {
                    option.status = "unchanged";
                    optionReview.push(option);
                  }
                  if (option.plan != initOption.plan)
                    if (option.plan != "") {
                      option.status = "modified";
                      option.oldPlan = initOption.plan;
                      optionReview.push(option);
                    } else {
                      option.status = "removed";
                      option.oldPlan = initOption.plan;
                      optionReview.push(option);
                    }
                  break;
                }
              }
          }
        }
        this.showSaveReview(optionReview);
      },
    },
  };
</script>

<style>
  .bordered {
    border: 1px solid #dbdbdb;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top;
  }
</style>
