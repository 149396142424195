<template>
  <div id="app">
    <AppHome />
  </div>
</template>

<script>
  import AppHome from "./components/AppHome.vue";

  export default {
    name: "AppMain",
    components: {
      AppHome,
    },
  };
</script>

<style>
  .EUR:after {
    content: " €";
  }
  .BRL:after {
    content: " R$";
  }
  .JPY:after {
    content: " ¥";
  }
  .CAD:after {
    content: " $ C";
  }
  .USD:after {
    content: " US$";
  }
  .select select option:disabled {
    color: #c3c3c3;
  }
  /* fix for buefy not compatible with fontawesome v6 */
  .svg-inline--fa {
    height: 1em !important;
  }
</style>
