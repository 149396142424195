<template lang="pug">
b-skeleton.container(v-if="isLoading")
.container(v-else)
  Hero(:title="installation.name")
    b-button.is-outlined(@click="goToInstallationPage(installation)") Installation
  b-table-wrapper(
    :endPointActions="sendTo",
    :sendData="sendData",
    @on-error="snackError",
    :allowExport="true",
    :table="{ bordered: false, narrowed: true, striped: false, hoverable: false }"
  )
    template(v-slot:orders="props")
      table.is-bordered.table(style="border-collapse: collapse")
        thead
          tr
            th Order ID
            th Generated
            th Status
            th Total price
            th Created at
            th Dongle ID
            th Name
            th State
            th WebOrders ID
            th Products
            th Price
        tbody
          tr(v-for="(rowdata, index) in props.row.rowData")
            td(:class="rowdata.orderIndex > 0 ? 'blank' : ''") {{ rowdata.id }}
            td(:class="rowdata.orderIndex > 0 ? 'blank' : ''") {{ rowdata.generated_by }}
            td(:class="rowdata.orderIndex > 0 ? 'blank' : ''") {{ rowdata.status }}
            td(:class="rowdata.orderIndex > 0 ? 'blank' : state.user.currency") {{ rowdata.total_price.toFixed(2) }}
            td(:class="rowdata.orderIndex > 0 ? 'blank' : ''") {{ formatDateTime(rowdata.created_at) }}
            td(:class="rowdata.targetIndex > 0 ? 'blank' : ''") {{ rowdata.dongle_id }}
            td(:class="rowdata.targetIndex > 0 ? 'blank' : ''") {{ rowdata.target_name }}
            td(:class="rowdata.targetIndex > 0 ? 'blank' : ''") {{ rowdata.state }}
            td(:class="rowdata.targetIndex > 0 ? 'blank' : ''") {{ rowdata.id_weborders }}
            td {{ rowdata.product }}
            td(:class="state.user.currency") {{ rowdata.vendor_price.toFixed(2) }}
</template>


<script>
  import Hero from "../partial/HerosWithButton";
  import bTableWrapper from "../partial/bTableWrapper";
  import { snackError } from "../utils/common";
  import { DateTime } from "luxon";

  export default {
    components: {
      Hero,
      bTableWrapper,
    },
    data: function () {
      return {
        isLoading: true,
        installation: null,
        sendTo: this.actions.listOrdersByInstallation,
        sendData: { id: this.$route.params.installation_id },
        snackError: snackError,
      };
    },
    mounted: async function () {
      await this.refresh();
      this.isLoading = false;
    },
    computed: {
      installationId: function () {
        return this.$route.params.installation_id;
      },
    },
    methods: {
      refresh: async function () {
        try {
          this.installation = await this.actions.getInstallationById({
            id: this.installationId,
          });
        } catch (error) {
          snackError(error);
        }
      },
      goToInstallationPage: function (installation) {
        this.$router.push({
          name: "installation_licenses",
          params: { installation_id: installation.id },
        });
      },
      formatDateTime: function (value) {
        return value
          ? DateTime.fromISO(value)
              .toLocaleString(DateTime.DATETIME_MED)
              .replaceAll(" ", " ")
          : "_";
      },
    },
  };
</script>

<style>
  .blank {
    border-top: none !important;
    border-bottom: none !important;
    color: white;
  }
</style>
