<template lang="pug">
.container.is-fluid.box
  .bordered.content.card.my-2
    p.subtitle Change review
    table
      thead
        tr
          th Name
          th Plan
          th Users per license
          th Status
      tbody
        tr(
          v-for="(option, index) in optionReview",
          :class="mapColorClass[option.status]",
          v-if="option.status != 'unchanged'"
        )
          td {{ option.targetName }}
          td {{ option.plan ? actions.getPlanFromID(option.plan).name : ' - ' }}
          td {{ option.userPerPlan > 1 ? option.userCount + '/' + option.userPerPlan : '' }}
          td {{ detailStatus(option) }}
        //- tr.is-flex.is-justify-content-space-around.is-align-items-center(
        //-   colspan="4"
        //- )
        //-   .has-text-success New
        //-   .has-text-info Modified
        //-   .has-text-danger Removed
  .container.is-flex.is-justify-content-space-around.is-align-items-center
    b-button.mt-5(
      type="is-info",
      icon-left="times-circle",
      icon-pack="fas",
      @click="cancelModal()"
    ) Cancel
    b-button.mt-5(
      type="is-primary",
      icon-left="save",
      icon-pack="fas",
      :loading="isLoading",
      @click="saveModifications()"
    ) Confirm
</template>

<script>
  import { snackError } from "../utils/common";
  export default {
    props: {
      optionReview: {
        type: Array,
        require: true,
      },
      installationID: [String, Number],
      refreshCallback: {
        type: Function,
        default: () => {
          return null;
        },
      },
    },
    data: function () {
      return {
        mapColorClass: {
          new: "has-text-success",
          unchanged: "",
          modified: "has-text-info",
          removed: "has-text-danger",
        },
        isLoading: false,
      };
    },
    methods: {
      detailStatus(option) {
        if (option.status == "new") return "New";
        if (option.status == "unchanged") return " - ";
        if (option.status == "modified")
          return "Replace : " + this.actions.getPlanFromID(option.oldPlan).name;
        if (option.status == "removed")
          return "Remove : " + this.actions.getPlanFromID(option.oldPlan).name;
      },
      cancelModal() {
        this.$emit("close");
      },
      saveModifications: async function () {
        let data = { options: [], installationID: this.installationID };
        for (let option of this.optionReview) {
          if (option.status != "unchanged") {
            data.options.push({
              id: option.id ? option.id : null,
              plan: option.plan != "" ? option.plan : 0,
              license_target: option.license_target,
            });
          }
        }
        try {
          this.isLoading = true;
          let response = await this.actions.createOrUpdateOptionList(data);
          this.changeDone = response.data.results ? true : false;
          this.error = null;
          this.isLoading = false;
          this.closeModal(response.data.results);
        } catch (error) {
          this.isLoading = false;
          this.error = error.response ? error.response.data.title : error;
          snackError(error);
        }
      },
      closeModal(created) {
        this.refreshCallback(created);
        this.$emit("close");
        this.$buefy.toast.open({
          message: "Targets updated",
          type: "is-success",
        });
      },
    },
  };
</script>

<style>
</style>
