<template lang='pug'>
div
  p.my-0(:class="state.user.currency") {{ Number.parseFloat(currencyPrice).toFixed(2) }}
  p.content.EUR.is-small.has-text-grey(v-if="state.user.currency != 'EUR'") {{ Number.parseFloat(smallEurPrice).toFixed(2) }}
</template>

<script>
export default {
  props: {
    EURprice: {
      type: Number,
      required: true
    },
    convert: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      // var : value
    }
  },
  computed: {
    currencyPrice: function () {
      if (this.state.user.currency == 'EUR' || !this.convert) {
        return this.EURprice
      }
      return this.actions.convert({ value: this.EURprice, revertConversion: false })
    },
    smallEurPrice: function () {
      if (this.convert) return this.EURprice
      return this.actions.convert({ value: this.EURprice, revertConversion: true })
    }
  }
}
</script>

<style>
</style>