import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Router from 'vue-router'
import router from './router'
import { OvermindPlugin } from './overmind'
import axios from 'axios'
// FontAwesome imports
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false

Vue.use(Router)

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconComponent: 'FontAwesomeIcon',
  defaultIconPack: "fas"
});


/* import font awesome icon component */
import {
  faAtom,
  faSignOut,
  faSearch,
  faBadgeCheck,
  faBan,
  faChevronDoubleUp,
  faChevronDoubleDown,
  faCalendarAlt,
  faCircleXmark,
  faCheckCircle,
  faTimesCircle,
  faUserPlus,
  faAngleLeft,
  faAngleRight,
  faEdit,
  faArrowUp,
  faPlus,
  faUndo,
  faSave,
  faShieldCheck,
  faFileCsv,
  faExclamationCircle,
  faTrashXmark,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faAtom,
  faSignOut,
  faSearch,
  faBadgeCheck,
  faBan,
  faChevronDoubleUp,
  faChevronDoubleDown,
  faCalendarAlt,
  faCircleXmark,
  faCheckCircle,
  faTimesCircle,
  faUserPlus,
  faAngleLeft,
  faAngleRight,
  faEdit,
  faArrowUp,
  faPlus,
  faUndo,
  faSave,
  faShieldCheck,
  faFileCsv,
  faExclamationCircle,
  faTrashXmark,
)

function getCsrftoken () {
  try {
    let csrftoken = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      .split('=')[1];
    return csrftoken
  } catch {
    return ""
  }
}
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-CSRFTOKEN'] = getCsrftoken()

Vue.use(OvermindPlugin)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
